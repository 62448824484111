<template>
  <div
    class="operators overlay"
    :style="{top: `${top}px`}"
  >
    <button
      v-for="(item, index) in menuItem"
      :key="`${index}-${item}`"
      :class="`image-button ${item}`"
      @click="click(item)"
    />
  </div>
</template>

<script>
export default {
  name: 'Operator',
  props: {
    top: {
      required: false,
      type: Number,
      default: 191,
    },
    menuItem: {
      required: false,
      type: Array,
      default: null,
    },
  },
  data() {
    return {};
  },
  methods: {
    click(title) {
      this.$emit('click', title);
    },
  },
};
</script>

<style lang="scss">
.operators {
  left: 15px;
  width: 32px;
  display: flex;
  flex-direction: column;
  button {
    cursor: pointer;
    margin-bottom: 8px;
    width: 32px;
    height: 32px;
    background-repeat: no-repeat;
  }

  button.map-mode {
    background-image: url("/asserts/icon/map-mapmode-icon-normal.svg");
  }
  button.center {
    background-image: url("/asserts/icon/map-center-icon-normal.svg");
  }
  button.undo {
    background-image: url("/asserts/icon/map-undo-icon.svg");
  }
  button.clear {
    background-image: url("/asserts/icon/map-clear-icon-normal.svg");
  }
  button.gps {
    background-image: url("/asserts/icon/center-by-gps-icon-normal.svg");
  }
  button.import {
    background-image: url("/asserts/icon/map-import-icon.svg");
  }
}
</style>

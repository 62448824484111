const WaypointNormalStyle = {
  waypoint: {
    displayName: 'Waypoint',
    url: '/asserts/icon/waypointtype-waypoint-icon.svg',
    anchor: {
      x: 22,
      y: 22,
    },
    labelOrigin: {
      x: 22,
      y: 22,
    },
    menu: {
      icon: '/asserts/icon/waypoint-command-icon-waypoint.svg',
    },
  },
  do_counting_control: {
    displayName: 'Counting',
    url: '/asserts/icon/waypointtype-counting-icon.svg',
    anchor: {
      x: 22,
      y: 22,
    },
    labelOrigin: {
      x: 22,
      y: 22,
    },
    menu: {
      icon: '/asserts/icon/waypoint-command-icon-counting.svg',
    },
    label: null,
  },
  land: {
    displayName: 'Landing',
    disabled: false,
    url: '/asserts/icon/waypointtype-landing-icon.svg',
    anchor: {
      x: 27,
      y: 17,
    },
    labelOrigin: {
      x: 27,
      y: 18,
    },
    menu: {
      icon: '/asserts/icon/waypoint-command-icon-landing.svg',
    },
    label: null,
  },
  nest: {
    displayName: 'Nest',
    disabled: true,
    url: '/asserts/icon/waypointtype-nest-icon.svg',
    anchor: {
      x: 22,
      y: 22,
    },
    labelOrigin: {
      x: 22,
      y: 22,
    },
    label: null,
    menu: {
      icon: '/asserts/icon/waypoint-command-icon-nest.svg',
    },
  },
  rtl: {
    displayName: 'Return_Home',
    url: '/asserts/icon/waypointtype-returnhome-icon.svg',
    anchor: {
      x: 22,
      y: 22,
    },
    labelOrigin: {
      x: 22,
      y: 22,
    },
    label: null,
    menu: {
      icon: '/asserts/icon/waypoint-command-icon-reternhome.svg',
    },
  },
  do_digicam_control: {
    displayName: 'Camera',
    url: '/asserts/icon/waypointtype-camera-icon.svg',
    anchor: {
      x: 23,
      y: 19,
    },
    labelOrigin: {
      x: 23,
      y: 19,
    },
    menu: {
      icon: '/asserts/icon/waypoint-command-icon-camera.svg',
    },
  },
  do_intervalcam_control: {
    displayName: 'Intervalcam',
    url: '/asserts/icon/waypointtype-interval-camera-icon.svg',
    anchor: {
      x: 23,
      y: 19,
    },
    labelOrigin: {
      x: 23,
      y: 19,
    },
    label: null,
    menu: {
      icon: '/asserts/icon/waypoint-command-icon-interval-camera.svg',
    },
  },
  fenceWaypoint: {
    url: '/asserts/icon/fence-waypoint-icon.svg',
    disabled: true,
    anchor: {
      x: 10.5,
      y: 10.5,
    },
    labelOrigin: {
      x: 0,
      y: 0,
    },
    label: null,
  },
  middleWaypoint: {
    url: '/asserts/icon/map-add-waypoint-icon.svg',
    disabled: true,
    anchor: {
      x: 10.5,
      y: 10.5,
    },
    labelOrigin: {
      x: 0,
      y: 0,
    },
    label: null,
  },
  takeoff: {
    displayName: 'Take_Off',
    disabled: true,
    url: '/asserts/icon/waypointtype-takeoff-icon.svg',
    anchor: {
      x: 22,
      y: 22,
    },
    labelOrigin: {
      x: 22,
      y: 22,
    },
    label: null,
    menu: {
      icon: '/asserts/icon/waypoint-command-icon-takeoff.svg',
    },
  },
  survey: {
    disabled: true,
    url: '/asserts/icon/create-route-waypoint-icon.svg',
    anchor: {
      x: 17,
      y: 17,
    },
    labelOrigin: {
      x: 17,
      y: 17,
    },
    label: null,
  },
};

const WaypointStyle = {
  waypoint: {
    displayName: 'Waypoint',
    url: '/asserts/icon/waypointtype-waypoint-icon.svg',
    anchor: {
      x: 13,
      y: 13,
    },
    labelOrigin: {
      x: 13,
      y: 13,
    },
    menu: {
      icon: '/asserts/icon/waypoint-command-icon-waypoint.svg',
    },
    scaledSize: {
      width: 26,
      height: 26,
    },
    scale: 0.578125,
  },
  do_counting_control: {
    displayName: 'Counting',
    url: '/asserts/icon/waypointtype-counting-icon.svg',
    anchor: {
      x: 13,
      y: 13,
    },
    labelOrigin: {
      x: 13,
      y: 13,
    },
    menu: {
      icon: '/asserts/icon/waypoint-command-icon-counting.svg',
    },
    scaledSize: {
      width: 26,
      height: 26,
    },
    scale: 0.578125,
    label: null,
  },
  land: {
    displayName: 'Landing',
    url: '/asserts/icon/waypointtype-landing-icon.svg',
    anchor: {
      x: 18,
      y: 15,
    },
    labelOrigin: {
      x: 18,
      y: 15,
    },
    menu: {
      icon: '/asserts/icon/waypoint-command-icon-landing.svg',
    },
    label: null,
    scaledSize: {
      width: 36,
      height: 29,
    },
    scale: 0.578125,
  },
  nest: {
    displayName: 'Nest',
    url: '/asserts/icon/waypointtype-nest-icon.svg',
    anchor: {
      x: 13,
      y: 13,
    },
    labelOrigin: {
      x: 13,
      y: 13,
    },
    label: null,
    menu: {
      icon: '/asserts/icon/waypoint-command-icon-nest.svg',
    },
    scaledSize: {
      width: 26,
      height: 26,
    },
    scale: 0.578125,
  },
  rtl: {
    displayName: 'Return Home',
    url: '/asserts/icon/waypointtype-returnhome-icon.svg',
    anchor: {
      x: 13,
      y: 13,
    },
    labelOrigin: {
      x: 13,
      y: 13,
    },
    label: null,
    menu: {
      icon: '/asserts/icon/waypoint-command-icon-reternhome.svg',
    },
    scaledSize: {
      width: 26,
      height: 26,
    },
    scale: 0.578125,
  },
  do_digicam_control: {
    displayName: 'Camera',
    url: '/asserts/icon/waypointtype-camera-icon.svg',
    anchor: {
      x: 11.5,
      y: 9.5,
    },
    labelOrigin: {
      x: 11.5,
      y: 9.5,
    },
    menu: {
      icon: '/asserts/icon/waypoint-command-icon-camera.svg',
    },
    scaledSize: {
      width: 23,
      height: 19,
    },
    scale: 0.578125,
  },
  do_intervalcam_control: {
    displayName: 'Intervalcam',
    url: '/asserts/icon/waypointtype-interval-camera-icon.svg',
    anchor: {
      x: 20,
      y: 16,
    },
    labelOrigin: {
      x: 23,
      y: 19,
    },
    label: null,
    menu: {
      icon: '/asserts/icon/waypoint-command-icon-interval-camera.svg',
    },
    scaledSize: {
      width: 26,
      height: 26,
    },
    scale: 0.578125,
  },
  fenceWaypoint: {
    url: '/asserts/icon/fence-waypoint-icon.svg',
    disabled: true,
    anchor: {
      x: 10.5,
      y: 10.5,
    },
    labelOrigin: {
      x: 0,
      y: 0,
    },
    label: null,
  },
  middleWaypoint: {
    url: '/asserts/icon/map-add-waypoint-icon.svg',
    disabled: true,
    anchor: {
      x: 10.5,
      y: 10.5,
    },
    labelOrigin: {
      x: 0,
      y: 0,
    },
    label: null,
  },
  takeoff: {
    displayName: 'Take Off',
    disabled: true,
    url: '/asserts/icon/waypointtype-takeoff-icon.svg',
    anchor: {
      x: 18,
      y: 15,
    },
    labelOrigin: {
      x: 18,
      y: 15,
    },
    label: null,
    menu: {
      icon: '/asserts/icon/waypoint-command-icon-takeoff.svg',
    },
    scaledSize: {
      width: 36,
      height: 29,
    },
    scale: 0.578125,
  },
};

const WaypointSelectedStyle = {
  waypoint: {
    displayName: 'Waypoint',
    url: '/asserts/icon/waypointtype-waypoint-selected-icon.svg',
    anchor: {
      x: 22,
      y: 22,
    },
    labelOrigin: {
      x: 22,
      y: 22,
    },
  },
  do_counting_control: {
    displayName: 'Counting',
    url: '/asserts/icon/waypointtype-counting-selected-icon.svg',
    anchor: {
      x: 22,
      y: 22,
    },
    labelOrigin: {
      x: 22,
      y: 22,
    },
    label: null,
  },
  land: {
    displayName: 'Landing',
    url: '/asserts/icon/waypointtype-landing-selected-icon.svg',
    anchor: {
      x: 27,
      y: 17,
    },
    labelOrigin: {
      x: 27,
      y: 18,
    },
    label: null,
  },
  nest: {
    displayName: 'Nest',
    url: '/asserts/icon/waypointtype-nest-selected-icon.svg',
    anchor: {
      x: 22,
      y: 22,
    },
    labelOrigin: {
      x: 22,
      y: 22,
    },
    label: null,
  },
  rtl: {
    displayName: 'Return Home',
    url: '/asserts/icon/waypointtype-returnhome-selected-icon.svg',
    anchor: {
      x: 22,
      y: 22,
    },
    labelOrigin: {
      x: 22,
      y: 22,
    },
    label: null,
  },
  do_digicam_control: {
    displayName: 'Camera',
    url: '/asserts/icon/waypointtype-camera-selected-icon.svg',
    anchor: {
      x: 23,
      y: 19,
    },
    labelOrigin: {
      x: 23,
      y: 19,
    },
  },
  do_intervalcam_control: {
    displayName: 'Intervalcam',
    url: '/asserts/icon/waypointtype-interval-camera-selected-icon.svg',
    anchor: {
      x: 23,
      y: 19,
    },
    labelOrigin: {
      x: 23,
      y: 19,
    },
    label: null,
  },
  takeoff: {
    displayName: 'Take Off',
    url: '/asserts/icon/waypointtype-takeoff-selected-icon.svg',
    anchor: {
      x: 22,
      y: 22,
    },
    labelOrigin: {
      x: 22,
      y: 22,
    },
    label: null,
  },
  survey: {
    disabled: true,
    url: '/asserts/icon/create-route-waypoint-selected-icon.svg',
    anchor: {
      x: 17,
      y: 17,
    },
    labelOrigin: {
      x: 17,
      y: 17,
    },
    label: null,
  },
};

const WaypointFinishedStyle = {
  waypoint: {
    displayName: 'Waypoint',
    url: '/asserts/icon/waypointtype-waypoint-finished-icon.svg',
    anchor: {
      x: 13,
      y: 13,
    },
    labelOrigin: {
      x: 13,
      y: 13,
    },
    scaledSize: {
      width: 26,
      height: 26,
    },
    scale: 0.578125,
  },
  do_counting_control: {
    displayName: 'Counting',
    url: '/asserts/icon/waypointtype-counting-finished-icon.svg',
    anchor: {
      x: 13,
      y: 13,
    },
    labelOrigin: {
      x: 13,
      y: 13,
    },
    scaledSize: {
      width: 26,
      height: 26,
    },
    scale: 0.578125,
    label: null,
  },
  land: {
    displayName: 'Landing',
    url: '/asserts/icon/waypointtype-landing-finished-icon.svg',
    anchor: {
      x: 18,
      y: 15,
    },
    labelOrigin: {
      x: 27,
      y: 18,
    },
    label: null,
    scaledSize: {
      width: 36,
      height: 29,
    },
    scale: 0.578125,
  },
  nest: {
    displayName: 'Nest',
    url: '/asserts/icon/waypointtype-nest-finished-icon.svg',
    anchor: {
      x: 13,
      y: 13,
    },
    labelOrigin: {
      x: 13,
      y: 13,
    },
    label: null,
    scaledSize: {
      width: 26,
      height: 26,
    },
    scale: 0.578125,
  },
  rtl: {
    displayName: 'Return Home',
    url: '/asserts/icon/waypointtype-returnhome-finished-icon.svg',
    anchor: {
      x: 13,
      y: 13,
    },
    labelOrigin: {
      x: 13,
      y: 13,
    },
    label: null,
    scaledSize: {
      width: 26,
      height: 26,
    },
    scale: 0.578125,
  },
  do_digicam_control: {
    displayName: 'Camera',
    url: '/asserts/icon/waypointtype-camera-finished-icon.svg',
    anchor: {
      x: 11.5,
      y: 9.5,
    },
    labelOrigin: {
      x: 11.5,
      y: 9.5,
    },
    scaledSize: {
      width: 23,
      height: 19,
    },
    scale: 0.578125,
  },
  do_intervalcam_control: {
    displayName: 'Intervalcam',
    url: '/asserts/icon/waypointtype-interval-camera-finished-icon.svg',
    anchor: {
      x: 20,
      y: 16,
    },
    labelOrigin: {
      x: 23,
      y: 19,
    },
    label: null,
    scaledSize: {
      width: 26,
      height: 26,
    },
    scale: 0.578125,
  },
  takeoff: {
    displayName: 'Take Off',
    url: '/asserts/icon/waypointtype-takeoff-finished-icon.svg',
    anchor: {
      x: 18,
      y: 15,
    },
    labelOrigin: {
      x: 18,
      y: 15,
    },
    label: null,
    scaledSize: {
      width: 36,
      height: 29,
    },
    scale: 0.578125,
  },
};

const WaypointNextStyle = {
  waypoint: {
    displayName: 'Waypoint',
    url: '/asserts/icon/waypointtype-waypoint-nexttarget-icon.svg',
    anchor: {
      x: 13,
      y: 13,
    },
    labelOrigin: {
      x: 13,
      y: 13,
    },
    scaledSize: {
      width: 26,
      height: 26,
    },
    scale: 0.578125,
  },
  do_counting_control: {
    displayName: 'Counting',
    url: '/asserts/icon/waypointtype-counting-nexttarget-icon.svg',
    anchor: {
      x: 13,
      y: 13,
    },
    labelOrigin: {
      x: 13,
      y: 13,
    },
    scaledSize: {
      width: 26,
      height: 26,
    },
    scale: 0.578125,
  },
  land: {
    displayName: 'Landing',
    url: '/asserts/icon/waypointtype-landing-nexttarget-icon.svg',
    anchor: {
      x: 18,
      y: 15,
    },
    labelOrigin: {
      x: 18,
      y: 15,
    },
    label: null,
    scaledSize: {
      width: 36,
      height: 29,
    },
    scale: 0.578125,
  },
  nest: {
    displayName: 'Nest',
    url: '/asserts/icon/waypointtype-nest-nexttarget-icon.svg',
    anchor: {
      x: 13,
      y: 13,
    },
    labelOrigin: {
      x: 13,
      y: 13,
    },
    label: null,
    scaledSize: {
      width: 26,
      height: 26,
    },
    scale: 0.578125,
  },
  rtl: {
    displayName: 'Return Home',
    url: '/asserts/icon/waypointtype-returnhome-nexttarget-icon.svg',
    anchor: {
      x: 13,
      y: 13,
    },
    labelOrigin: {
      x: 13,
      y: 13,
    },
    label: null,
    scaledSize: {
      width: 26,
      height: 26,
    },
    scale: 0.578125,
  },
  do_digicam_control: {
    displayName: 'Camera',
    url: '/asserts/icon/waypointtype-camera-nexttarget-icon.svg',
    anchor: {
      x: 11.5,
      y: 9.5,
    },
    labelOrigin: {
      x: 11.5,
      y: 9.5,
    },
    scaledSize: {
      width: 23,
      height: 19,
    },
    scale: 0.578125,
  },
  do_intervalcam_control: {
    displayName: 'Intervalcam',
    url: '/asserts/icon/waypointtype-interval-camera-nexttarget-icon.svg',
    anchor: {
      x: 20,
      y: 16,
    },
    labelOrigin: {
      x: 23,
      y: 19,
    },
    label: null,
    scaledSize: {
      width: 26,
      height: 26,
    },
    scale: 0.578125,
  },
  takeoff: {
    displayName: 'Take Off',
    url: '/asserts/icon/waypointtype-takeoff-nexttarget-icon.svg',
    anchor: {
      x: 18,
      y: 15,
    },
    labelOrigin: {
      x: 18,
      y: 15,
    },
    label: null,
    scaledSize: {
      width: 36,
      height: 29,
    },
    scale: 0.578125,
  },
};

const Icon = {
  nest: {
    url: '/asserts/icon/map-nest-icon-close.svg',
    anchor: {
      x: 16,
      y: 8,
    },
    labelOrigin: {
      x: 0,
      y: 0,
    },
    label: null,
  },
  camera: {
    url: '/asserts/icon/map-ipcam-icon.svg',
    anchor: {
      x: 12,
      y: 12,
    },
    labelOrigin: {
      x: 0,
      y: 0,
    },
    label: null,
  },
  weather: {
    url: '/asserts/icon/map-weather-station-icon.svg',
    anchor: {
      x: 10,
      y: 27,
    },
    labelOrigin: {
      x: 0,
      y: 0,
    },
    label: null,
  },
  newClickAndGo: {
    url: '/asserts/icon/mapview-clickandgo-newwpt-icon.svg',
    anchor: {
      x: 18,
      y: 18,
    },
    labelOrigin: {
      x: 0,
      y: 0,
    },
    label: null,
    scaledSize: {
      width: 36,
      height: 36,
    },
  },
  clickAndGo: {
    url: '/asserts/icon/mapview-clickandgo-icon.svg',
    anchor: {
      x: 18,
      y: 18,
    },
    labelOrigin: {
      x: 0,
      y: 0,
    },
    label: null,
    scaledSize: {
      width: 36,
      height: 36,
    },
  },
  elzSelected: {
    url: '/asserts/icon/map-elz-icon-selected.svg',
    disabled: true,
    anchor: {
      x: 10.5,
      y: 10.5,
    },
    labelOrigin: {
      x: 0,
      y: 0,
    },
    label: null,
  },
  elz: {
    url: '/asserts/icon/map-elz-icon.svg',
    disabled: true,
    anchor: {
      x: 10.5,
      y: 10.5,
    },
    labelOrigin: {
      x: 0,
      y: 0,
    },
    label: null,
  },
  elzChecked: {
    url: '/asserts/icon/select-elz-icon.svg',
    disabled: true,
    anchor: {
      x: 10.5,
      y: 10.5,
    },
    labelOrigin: {
      x: 0,
      y: 0,
    },
    label: null,
  },
};

const DroneStyle = {
  base: {
    normal: {
      // // setting for calibration
      // // google.maps.SymbolPath.CIRCLE = 0
      // path: 0,
      // fillOpacity: 1,
      // fillColor: '#FFFFFF',
      // scale: 18.5,
      // strokeWeight: 0,
      url: '/asserts/icon/map-drone-background-icon.svg',
      anchor: {
        x: 13,
        y: 13,
      },
      labelOrigin: {
        x: 0,
        y: 0,
      },
      label: null,
      scale: 0.578125,
      scaledSize: {
        width: 26,
        height: 26,
      },
    },
    activate: {
      // // setting for calibration
      // // google.maps.SymbolPath.CIRCLE = 0
      // path: 0,
      // fillOpacity: 1,
      // fillColor: '#FFFFFF',
      // scale: 21.5,
      // strokeWeight: 6,
      // strokeColor: '#ffc900',
      // strokeOpacity: 0.7,
      url: '/asserts/icon/map-drone-background-selected.svg',
      anchor: {
        x: 18,
        y: 18,
      },
      labelOrigin: {
        x: 0,
        y: 0,
      },
      label: null,
      scale: 0.578125,
      scaledSize: {
        width: 36,
        height: 36,
      },
    },
  },
  standby: {
    main: {
      path: 'm 20.79346,6.94422 -9.69746,24.24366 9.69746,-5.65847 10.50559,6.46659 z',
      fillOpacity: 1,
      fillColor: '#3d7fb8',
      strokeWeight: 0,
      label: null,
      anchor: {
        x: 21,
        y: 21,
      },
      scale: 0.6,
    },
    sub: {
      path: 'M 20.793,25.51921 31.29815,32.033 20.793,6.94417 Z',
      fillOpacity: 1,
      fillColor: '#0c5c9c',
      strokeWeight: 0,
      label: null,
      anchor: {
        x: 21,
        y: 21,
      },
      scale: 0.6,
    },
  },
  error: {
    main: {
      path: 'm 20.79346,6.94422 -9.69746,24.24366 9.69746,-5.65847 10.50559,6.46659 z',
      fillOpacity: 1,
      fillColor: '#E35B00',
      strokeWeight: 0,
      label: null,
      anchor: {
        x: 21,
        y: 21,
      },
      scale: 0.6,
    },
    sub: {
      path: 'M 20.793,25.51921 31.29815,32.033 20.793,6.94417 Z',
      fillOpacity: 1,
      fillColor: '#C43600',
      strokeWeight: 0,
      label: null,
      anchor: {
        x: 21,
        y: 21,
      },
      scale: 0.6,
    },
  },
  offline: {
    main: {
      path: 'm 20.79346,6.94422 -9.69746,24.24366 9.69746,-5.65847 10.50559,6.46659 z',
      fillOpacity: 1,
      fillColor: '#7B7B7B',
      strokeWeight: 0,
      label: null,
      anchor: {
        x: 21,
        y: 21,
      },
      scale: 0.6,
    },
    sub: {
      path: 'M 20.793,25.51921 31.29815,32.033 20.793,6.94417 Z',
      fillOpacity: 1,
      fillColor: '#4E4E4E',
      strokeWeight: 0,
      label: null,
      anchor: {
        x: 21,
        y: 21,
      },
      scale: 0.6,
    },
  },
  replay: {
    main: {
      path: 'm 20.79346,6.94422 -9.69746,24.24366 9.69746,-5.65847 10.50559,6.46659 z',
      fillOpacity: 1,
      fillColor: '#7B7B7B',
      strokeWeight: 0,
      label: null,
      anchor: {
        x: 21,
        y: 21,
      },
      scale: 0.6,
    },
    sub: {
      path: 'M 20.793,25.51921 31.29815,32.033 20.793,6.94417 Z',
      fillOpacity: 1,
      fillColor: '#4E4E4E',
      strokeWeight: 0,
      label: null,
      anchor: {
        x: 21,
        y: 21,
      },
      scale: 0.6,
    },
  },
  charging: {
    main: {
      path: 'm 20.79346,6.94422 -9.69746,24.24366 9.69746,-5.65847 10.50559,6.46659 z',
      fillOpacity: 1,
      fillColor: '#84D851',
      strokeWeight: 0,
      label: null,
      anchor: {
        x: 21,
        y: 21,
      },
      scale: 0.6,
    },
    sub: {
      path: 'M 20.793,25.51921 31.29815,32.033 20.793,6.94417 Z',
      fillOpacity: 1,
      fillColor: '#6FB666',
      strokeWeight: 0,
      label: null,
      anchor: {
        x: 21,
        y: 21,
      },
      scale: 0.6,
    },
  },
};

const DroneStyleImage = {
  standby: {
    url: '/asserts/icon/dronestatus-ready-icon.svg',
    anchor: {
      x: 16,
      y: 17,
    },
    labelOrigin: {
      x: 0,
      y: 0,
    },
    label: null,
    scale: 1.375,
  },
  error: {
    url: '/asserts/icon/dronestatus-error-icon.svg',
    anchor: {
      x: 16,
      y: 17,
    },
    labelOrigin: {
      x: 0,
      y: 0,
    },
    label: null,
    scale: 1.375,
  },
  offline: {
    url: '/asserts/icon/dronestatus-offline-icon.svg',
    anchor: {
      x: 16,
      y: 17,
    },
    labelOrigin: {
      x: 0,
      y: 0,
    },
    label: null,
    scale: 1.375,
  },
  charging: {
    url: '/asserts/icon/dronestatus-charging-icon.svg',
    anchor: {
      x: 16,
      y: 17,
    },
    labelOrigin: {
      x: 0,
      y: 0,
    },
    label: null,
    scale: 1.375,
  },
};

export {
  WaypointNormalStyle, WaypointStyle, WaypointSelectedStyle, WaypointFinishedStyle, WaypointNextStyle, Icon, DroneStyle, DroneStyleImage,
};

<template>
  <div class="overlay vertical-group-button">
    <el-button size="mini" class="fa-button" type="" @click="$emit('zoomin')">
      <img src="/asserts/icon/map-zoomin-icon-normal.svg" />
    </el-button>
    <el-button size="mini" class="fa-button" type="" @click="$emit('zoomout')">
      <img src="/asserts/icon/map-zoomout-icon-normal.svg" />
    </el-button>
  </div>
</template>

<script>
export default {
  name: "ZoomButton",
};
</script>

<style lang="scss" scoped>
.overlay.vertical-group-button {
  top: auto;
  bottom: 85px;
  left: auto;
  right: 12px;
  width: 32px;
}

.vertical-group-button {
  .el-button + .el-button {
    margin-left: 0;
  }

  .el-button {
    border-color: #cbcbcb;
    border-width: 1px;
    border-radius: 4px;
    padding-left: 2px;
    padding-right: 2px;
    width: 32px;
    height: 32px;
  }

  > .el-button:not(:first-child):not(:last-child) {
    border-radius: 0;
  }

  > .el-button:first-child {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom: 0px;
  }

  > .el-button:last-child {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}
</style>
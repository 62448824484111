<template>
  <div>
    <vl-layer-vector :z-index="zIndex">
      <vl-source-vector
        :ident="`MultiplePoint-${source}-${type}-${value.id}`"
        :features.sync="pointSource"
        ref="pointSource"
      ></vl-source-vector>
      <vl-style-func :factory="pointStyles" />
    </vl-layer-vector>
    <vl-interaction-modify v-if="!readOnly"
                           :source="`MultiplePoint-${source}-${type}-${value.id}`"
                           :pixel-tolerance="pixelTolerance"
                           @modifystart="beforeInput"
                           @modifyend="input"
    >
      <vl-style-box>
      </vl-style-box>
    </vl-interaction-modify>
  </div>
</template>

<script>
import config from 'config';
import _ from 'lodash';
import { CopyObject } from "@/utils/common.js";
import { GoogleMapsMarkerToOl } from '@/utils/map.js';
import { Style, Icon, Text, Fill } from 'ol/style.js';
import { transform as ProjectTransform } from 'ol/proj';

import { Icon as MarkerIcon, WaypointNormalStyle as WaypointIcon } from '@/components/GoogleMaps/MarkerStyle.js';

export default {
  name: "MultiplePoint",
  data() {
    return {
      pointSource: [],
    };
  },
  mounted() {
    this.loadFromProps();
  },
  methods: {
    beforeInput() {
      this.$emit('before-input');
    },
    input(event) {
      // 注意有坑
      // 1. modifyend 發生時，通常 pointSource 還沒更新
      //    modifyend 會比 pointSource 更新更早發生
      // 2. OpenLayers 的 getArray 拿到的 Feature 順序
      //    不一定跟 pointSource 對齊
      //    目前已知初始化後才建立的 Feature 會在最前面
      // 3. 沒有拖曳的點也有機會被動到，尤其是 Takeoff
      //    像是 22.3374069786423 進去會變成 22.337406978642292
      let eventFeatures = event.features.getArray();
      let returnData = CopyObject(this.value);
      returnData.changed = [];

      for (var feature of eventFeatures) {
        let properties = feature.getProperties();
        let index = properties.sequence;
        let coordFromOl = feature.getGeometry().getCoordinates();
        let coordForVl = ProjectTransform(coordFromOl, config.openlayers.olProjection, config.openlayers.vlProjection);
        let same = _.isEqual(returnData.list[index].coordinate, coordForVl);
        if (!same) {
          returnData.changed.push(index);
          returnData.list[index].coordinate = coordForVl;
        }
      }
      this.$emit('input', returnData);
    },
    loadFromProps() {
      this.pointSource = this.value.list.map((point, index) => {
        let pointData = { ...point };
        delete pointData.coordinate;
        return {
          type: "Feature",
          id: `MultiplePoint-${this.type}-${this.value.id}-${index}`,
          geometry: {
            type: "Point",
            coordinates: point.coordinate,
          },
          properties: {
            id: this.value.id,
            name: this.value.name,
            type: this.type,
            source: this.source,
            sequence: index,
            ...pointData,
          },
        };
      });
    },
    pointStyles() {
      let vueInstance = this;
      return function(feature) {
        let style = [];
        let properties = feature.getProperties();
        let iconConfig;
        if (typeof(properties.icon) === 'string') {
          iconConfig = properties.iconSet[properties.icon];
        } else {
          iconConfig = properties.icon;
        }
        let zIndex = vueInstance.zIndex + properties.sequence;

        if (iconConfig.label === null) {
          style.push(new Style({
            image: new Icon(GoogleMapsMarkerToOl(iconConfig)),
            zIndex,
          }));
        } else {
          style.push(new Style({
            image: new Icon(GoogleMapsMarkerToOl(iconConfig)),
            text: new Text({
              text: properties.sequence.toString(),
              scale: 1.6,
              fill: new Fill({
                color: 'white'
              })
            }),
            zIndex,
          }));
        }

        return style;
      }
    },
  },
  computed: {
    pixelTolerance() {
      switch (this.source) {
        case 'mission':
          return 20;
        default:
          return 10;
      }
    },
  },
  watch: {
    value(now, old) {
      if (_.isEqual(now, old)) {
        return;
      }
      this.loadFromProps();
    }
  },
  props: {
    readOnly: {
      type: Boolean,
      required: false,
      default: false
    },
    type: {
      type: String,
      required: false,
      default: "point",
    },
    source: {
      type: String,
      required: false,
      default: "camera",
    },
    value: {
      type: Object,
      required: true,
      default() {
        return {
          // Device List
          list: [
            {
              id: "8ae34f28-28df-4dcd-bae7-11edfa63c059",
              name: 'Nest 1',
              icon: "nest",
              iconSet: MarkerIcon,
              coordinate: [120.61015440692902, 22.33640143225972]
            },
            {
              id: "8ae34f28-28df-4dcd-bae7-11edfa63c060",
              name: 'Nest 2',
              icon: "nest",
              iconSet: MarkerIcon,
              coordinate: [120.61025440692902, 22.33640143225972]
            },
          ],

          // Mission, NoFlyZone List
          // id: "8ae34f28-28df-4dcd-bae7-11edfa630000",
          // name: "MultiplePointMission",
          // list: [
          //   {
          //     icon: 'waypoint',
          //     iconSet: WaypointIcon,
          //     coordinate: [120.61015440692902, 22.33640143225972]
          //   },
          //   {
          //     icon: 'waypoint',
          //     iconSet: WaypointIcon,
          //     coordinate: [120.61015440692902, 22.33641143225972]
          //   },
          //   {
          //     icon: 'land',
          //     iconSet: WaypointIcon,
          //     coordinate: [120.61015440692902, 22.33642143225972]
          //   },
          // ],
        };
      }
    },
    zIndex: {
      type: Number,
      default: 11,
    },
  }
};
</script>
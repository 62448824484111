import { Map } from 'vue2-google-maps';

const postitionConstant = {
  BOTTOM_CENTER: 11,
  BOTTOM_LEFT: 10,
  BOTTOM_RIGHT: 12,
  LEFT_BOTTOM: 6,
  LEFT_CENTER: 4,
  LEFT_TOP: 5,
  RIGHT_BOTTOM: 9,
  RIGHT_CENTER: 8,
  RIGHT_TOP: 7,
  TOP_CENTER: 2,
  TOP_LEFT: 1,
  TOP_RIGHT: 3,
};

const props = {
  center: {
    required: true,
    twoWay: true,
    type: Object,
    noBind: true,
  },
  zoom: {
    required: false,
    twoWay: true,
    type: Number,
    noBind: true,
  },
  heading: {
    type: Number,
    twoWay: true,
  },
  mapTypeId: {
    twoWay: true,
    type: String,
  },
  tilt: {
    twoWay: true,
    type: Number,
  },
  options: {
    type: Object,
    default() {
      return {
        styles: [
          {
            featureType: 'poi',
            elementType: 'all',
            stylers: [{ visibility: 'off' }],
          },
          {
            featureType: 'transit',
            elementType: 'all',
            stylers: [{ visibility: 'off' }],
          },
        ],
        zoomControl: false,
        mapTypeControl: false,
        scaleControl: true,
        streetViewControl: false,
        rotateControl: true,
        fullscreenControl: false,
        disableDefaultUi: false,
      };
    },
  },
};

export default {
  extends: Map,
  props,
  name: 'DefaultMap',
  mounted() {
    this.$root.$gmap = {
      map: this,
    };
  },
  destroyed() {
    delete this.$root.$gmap.map;
    delete this.$root.$gmap;
  },
};

import { appAxios as axios } from '@/services/api/';

const apiUrl = '/v1';

export default {
  get(id) {
    return axios.get(`${apiUrl}/noflyzones/${id}`).then((response) => response.data);
  },
  getList(boundary) {
    let queryString;
    if (boundary && boundary.join) {
      queryString = `boundary=${encodeURI(boundary.join(','))}`;
    }
    return axios.get(`${apiUrl}/noflyzones?${queryString}`).then((response) => response.data);
  },
  create(zone) {
    return axios.post(`${apiUrl}/noflyzones`, zone).then((response) => response.data);
  },
  update(zoneId, zone) {
    return axios.put(`${apiUrl}/noflyzones/${zoneId}`, zone).then((response) => response.data);
  },
  delete(zoneId) {
    return axios.delete(`${apiUrl}/noflyzones/${zoneId}`).then((response) => response.data);
  },
  importWithBoundary(boundary) {
    let queryString;
    if (boundary && boundary.join) {
      queryString = `boundary=${encodeURI(boundary.join(','))}`;
    }
    return axios.put(`${apiUrl}/noflyzones_import?${queryString}`, {}).then((response) => response.data);
  },
  clearWithBoundary(boundary) {
    let queryString;
    if (boundary && boundary.join) {
      queryString = `boundary=${encodeURI(boundary.join(','))}`;
    }
    return axios.delete(`${apiUrl}/noflyzones_import?${queryString}`, {}).then((response) => response.data);
  },
};

<template>
  <div>
    <default-layer :center="mapCenter"
                   @update:center="centerChange"
                   :zoom="mapZoom"
                   @update:zoom="zoomChange"
                   :mapTypeId="mapTypeId"
                   @click="(e, f) => { $emit('map-click', e, f) }"
                   @update:bounds="boundsChange"
                   :layers-count.sync="layersCount"
                   :interactive="interactive"
                   @map-rendercomplete="(e) => { $emit('map-rendercomplete', e); }"
                   @rendercomplete="rendercomplete"
                   :overwriteSource="overwriteSource"
                   ref="layers"
                   :z-index="1"
    >
      <!-- add layers always update rendercomplete method, or map-rendercomplete will fire multiple times -->
      <fly-area v-if="fence"
                :zone="fence"
                :readonly="true"
                :z-index="2"
                zoneType="fence"
      />
      <template v-for="(zone, index) in noFlyZone">
        <component :is="formatNoFlyZoneTypeToOlComponent(zone.type)"
                    :key="`Zones-NoFlyZone-${zone.id}`"
                    :zone="zone"
                    :z-index="3 + index"
        />
      </template>


      <multiple-point v-if="showNest && nests"
                      source="nests"
                      :read-only="true"
                      :value="nests"
                      :z-index="3 + noFlyZone.length"
      />

      <multiple-point v-if="showCamera && cameras"
                      source="cameras"
                      :read-only="true"
                      :value="cameras"
                      :z-index="3 + noFlyZone.length + 1"
      />

      <multiple-point v-if="showWeather && weather_stations"
                      source="weather_stations"
                      :read-only="true"
                      :value="weather_stations"
                      :z-index="3 + noFlyZone.length + 2"
      />

      <slot></slot>
    </default-layer>

    <div class="fms-openlayers-control" v-if="interactive">
      <zoom-button @zoomin="zoomIn"
                   @zoomout="zoomOut"
      />
      <operator @click="operatorClick"
                :top="operatorTopPosition"
                :menu-item="operatorItems"
      />
    </div>
    <slot name="non-layers" />
  </div>
</template>

<script>
  import { PolygonsApiToOl, GeographicApiToOl } from '@/utils/map.js';
  import { formatNoFlyZoneTypeToOlComponent } from '@/utils/map.js';
  import { logger } from "@/logger/index";
  import { transformExtent } from 'ol/proj';

  import Operator from '@/components/Maps/Operator.vue'
  import ZoomButton from '@/components/Maps/ZoomButton.vue';

  import DefaultLayer from '@/components/OpenLayers/DefaultLayers.vue';
  import FlyArea from '@/components/OpenLayers/FlyArea.vue';
  import MultiplePoint from '@/components/OpenLayers/MultiplePoint.vue';

  import NestApi from '@/services/api/domain/group/nest';
  import CameraApi from '@/services/api/domain/group/camera';
  import WeatherApi from '@/services/api/domain/group/weather';
  import NoFlyZoneAPI from '@/services/api/domain/noFlyZone.js';

  import { Icon as MarkerIcon } from '@/components/GoogleMaps/MarkerStyle.js';
  import { GoogleMapsMarkerToOl } from '@/utils/map.js';
  import { mapActions, mapGetters } from 'vuex';
  import config from 'config';

  export default {
    name: 'FlightMapByOpenLayers',
    components: {
      DefaultLayer,
      FlyArea,
      MultiplePoint,
      ZoomButton,
      Operator,
    },
    data() {
      return {
        noFlyZone: [],
        fence: null,
        nests: null,
        cameras: null,
        weather_stations: null,
        lastBoundsInteger: null,
        layersCount: 0,
      };
    },
    computed: {
      mapTypeId() {
        let set = this.mapType % this.layersCount;
        return set;
      },
      ...mapGetters({
        group: 'user/group',
        mapType: 'map/type',
        mapCenter: 'map/centerOl',
        mapZoom: 'map/zoom',
        mapBoundsInteger: 'map/boundsInteger',
      }),
    },
    watch: {
      async mapBoundsInteger(bounds, oldBounds) {
        if (bounds == null) {
          this.noFlyZone = [];
          return;
        }

        if (_.isEqual(bounds, oldBounds)) {
          return;
        }

        let loadNoFlyZone = this.loadItems.indexOf('no_fly_zone') >=0 ;
        if (!loadNoFlyZone) {
          return;
        }
        try {
          let { data } = await NoFlyZoneAPI.getList(bounds);
          data.no_fly_zones = data.no_fly_zones.map(this.formatNoFlyZones);
          this.noFlyZone = data.no_fly_zones;
        } catch (error) {
          this.$showFail(error);
        }
      },
    },
    beforeCreate() {
      this.$store.dispatch('map/reset');
    },
    created() {
      let _this = this;
      let loadFecne = this.loadItems.indexOf('fence') >=0 ;
      let loadNest = this.loadItems.indexOf('nests') >=0 ;
      let loadCamera = this.loadItems.indexOf('cameras') >=0 ;
      let loadWeather = this.loadItems.indexOf('weather_stations') >=0;
      let loadNoFlyZone = this.loadItems.indexOf('no_fly_zone') >=0;
      let group = this.group;
      if (this.groupData != null) {
        group = this.groupData;
      }

      if (group == null) {
        return;
      }

      if (loadFecne) {
        let fenceCoordinates = PolygonsApiToOl([group.fence])
        this.fence = {
          id: 'fence',
          name: 'fence',
          coordinates: fenceCoordinates,
        };
      }

      if (loadNest) {
        NestApi.getAll(group.id).then(response => {
          let data = response.data.nests;

          let multiplePointList = data.map(device => {
            return {
              icon: 'nest',
              iconSet: MarkerIcon,
              ...device,
              coordinate: GeographicApiToOl(device)
            };
          })

          _this.nests = {
            id: group.id + '-nests',
            name: group.name + ' nests',
            list: multiplePointList,
          };
        }).catch(this.$showFail);
      }

      if (loadCamera) {
        CameraApi.getAll(group.id).then(response => {
          let data = response.data.cameras;

          let multiplePointList = data.map(device => {
            return {
              icon: 'camera',
              iconSet: MarkerIcon,
              ...device,
              coordinate: GeographicApiToOl(device)
            };
          })

          _this.cameras = {
            id: group.id + '-cameras',
            name: group.name + ' cameras',
            list: multiplePointList,
          };
        }).catch(this.$showFail);
      }

      if (loadWeather) {
        WeatherApi.getAll(group.id).then(response => {
          let data = response.data.stations;

          let multiplePointList = data.map(device => {
            return {
              icon: 'weather',
              iconSet: MarkerIcon,
              ...device,
              coordinate: GeographicApiToOl(device)
            };
          })

          _this.weather_stations = {
            id: group.id + '-weather_stations',
            name: group.name + ' weather stations',
            list: multiplePointList,
          };
        }).catch(this.$showFail);
      }

      if (loadNoFlyZone) {
        if (this.mapBoundsInteger != null) {
          NoFlyZoneAPI.getList(this.mapBoundsInteger).then(response => {
            let data = response.data;

            data.no_fly_zones = data.no_fly_zones.map(this.formatNoFlyZones);
            this.noFlyZone = data.no_fly_zones;
          });
        }
      }
    },
    methods: {
      async fitBounds(bounds, options) {
        let olView = await this.$refs.layers.getOlView()
        if (options == null) {
          options = {
            padding: [5,5,5,5]
          };
        }
        return olView.fit(
          transformExtent(bounds, config.openlayers.vlProjection, config.openlayers.olProjection),
          options,
        );
      },
      formatNoFlyZones(zone) {
        zone.coordinates = PolygonsApiToOl(zone.polygons)
        delete zone.polygons;
        return zone;
      },
      operatorClick(name) {
        switch(name) {
          case 'map-mode':
            this.switchType();
            break;
          case 'center':
            this.goGroupCenter();
            break;
          default:
            this.$emit('operator', name);
        }
      },
      mapFindFeatureOnCoordinate(coordinate) {
        return this.$refs.layers.mapFindFeatureOnCoordinate(coordinate);
      },
      rendercomplete(event) {
        let layersToRender = 0;

        // #19 fly-area
        if (this.fence) {
          layersToRender += 1;
        }

        // #25 template (Any type of NoFlyZones)
        layersToRender += this.noFlyZone.length;

        // #34 multiple-point (nests)
        if (this.showNest && this.nests) {
          layersToRender += 1;
        }

        // #41 multiple-point (cameras)
        if (this.showCamera && this.cameras) {
          layersToRender += 1;
        }

        // #48 multiple-point (weather)
        if (this.showWeather && this.weather_stations) {
          layersToRender += 1;
        }

        // #55 slot
        layersToRender += this.additionalLayers;
        
        if (event.frameState.layerStatesArray.length < layersToRender ) {
          logger.debug('some layer not load');
          return;
        }

        for (let layer of event.frameState.layerStatesArray) {
          if (layer.sourceState !== 'ready') {
            logger.debug('layer not ready', layer);
            return;
          }
        }

        logger.debug('everything loaded');
        this.$emit('map-rendercomplete', event);
      },
      formatNoFlyZoneTypeToOlComponent,
      ...mapActions({
        zoomChange: 'map/zoomUpdate',
        zoomIn: 'map/zoomIn',
        zoomOut: 'map/zoomOut',
        switchType: 'map/typeNext',
        mapReset: 'map/reset',
        centerChange : 'map/centerUpdateOl',
        boundsChange: 'map/boundsUpdateOl',
        goGroupCenter: 'map/goGroupCenter',
      }),
    },
    props: {
      interactive: {
        required: false,
        default: true,
      },
      groupData: {
      },
      operatorTopPosition: {
      },
      operatorItems: {
        type: Array,
        default() {
          return [ 'map-mode', 'center' ];
        },
      },
      loadItems: {
        type: Array,
        default() {
          return [
            'fence',
            'no_fly_zone',
            'cameras',
            'weather_stations',
            'nests'
          ];
        },
      },
      showNest: {
        type: Boolean,
        default: true,
      },
      showCamera: {
        type: Boolean,
        default: true,
      },
      showWeather: {
        type: Boolean,
        default: true,
      },
      overwriteSource: {},
      additionalLayers: {
        // map-rendercomplete event require set this value correctly
        // or it will fire multiple times.
        type: Number,
        default: 1,
      },
    },
  };
</script>

<style lang="scss">
</style>
<template>
  <div class="vue-map-container">
    <vl-map ref="vlMap"
            :default-controls="controls"
            :interactions="interactions"
            @postrender="postrender"
            @singleclick="mapClick"
            @moveend="updateBounds"
            @rendercomplete="rendercomplete"
    >
      <vl-view ref="vlView"
               :zoom="zoom"
               @update:zoom="(e) => { $emit('update:zoom', e); }"
               :center="center"
               @update:center="centerUpdate"
               :rotation="0"
      />

      <vl-layer-tile v-for="(layer, count) in layersTile" 
                     :key="layer.name"
                     :id="layer.name"
                     :visible="count === mapTypeId"
                     :z-index="zIndex"
      >
        <component :is="layer.component"
                   v-bind="layer"
                   @tileloadstart="e => tileToLoad += 1"
                   @tileloadend="e => tileLoaded += 1"
        ></component>
      </vl-layer-tile>
      <slot />
    </vl-map>
  </div>
</template>

<script>
  import _ from 'lodash';
  import { transformExtent } from 'vuelayers/lib/ol-ext';
  import { defaults as DefaultControls } from 'ol/control';
  import { defaults as DefaultInteraction } from 'ol/interaction';
  import { FormatBounds } from '@/utils/map.js';
  import { getBottomLeft, getTopRight } from 'ol/extent.js';
  import { toLonLat, transform as ProjectTransform } from 'ol/proj.js';
  import { mapActions, mapGetters } from 'vuex';
  import config from 'config';

  export default {
    name: 'DefaultLayers',
    components: {
    },
    props: {
      interactive: {
        required: false,
        default: true,
      },
      center: {
        required: false,
        type: Array,
        twoWay: true,
        default: function() {
          return [0, 0];
        },
      },
      layersCount: {
        required: false,
        type: Number,
        default: 0,
      },
      mapTypeId: {
        required: true,
        type: Number,
      },
      rotate: {
        required: false,
        type: Number,
        default: 0,
      },
      zoom: {
        type: Number,
        default: 2,
      },
      zIndex: {
        default: 1,
      },
      overwriteSource: {},
    },
    data() {
      return {
        olMap: null,
        olView: null,
        tileToLoad: 0,
        tileLoaded: 0,
        tileWanted: null,
        layersImage: process.env.LAYERS_IMAGE ? JSON.parse(process.env.LAYERS_IMAGE): [],
        layersVector: process.env.LAYERS_VECTOR ? JSON.parse(process.env.LAYERS_VECTOR): [],
        layersVectorTile: process.env.LAYERS_VECTOR_TILE ? JSON.parse(process.env.LAYERS_VECTOR_TILE): [],
      };
    },
    computed: {
      ...mapGetters({
        olSourceList: 'user/olSource',
      }),
      layersTile() {
        if (this.overwriteSource instanceof Array) {
          return this.overwriteSource;
        }
        return this.olSourceList;
      },
      controls() {
        if (this.interactive) {
          return {
            zoom: false,
            rotate: false,
          };
        } else {
          return {
            rotate: false,
            attribution: true,
            attributionOptions: {
              collapsible: false,
            },
            zoom: false,
          };
        }
      },
      interactions() {
        if (this.interactive) {
          return DefaultInteraction();
        } else {
          return null;
        }
      },
    },
    methods: {
      mapClick(e) {
        let features = this.mapClickFindFeatures(e.pixel)
        this.$emit('click', e, features);
      },
      mapFindFeatureOnCoordinate(coordinate) {
        let coordinateForOl = ProjectTransform(coordinate, config.openlayers.vlProjection, config.openlayers.olProjection)
        let pixel = this.olMap.getPixelFromCoordinate(coordinateForOl);
        return this.mapClickFindFeatures(pixel);
      },
      mapClickFindFeatures(pixel) {
        let findFeatures = [];
        this.olMap.forEachFeatureAtPixel(pixel, (feature, layer) => {
          findFeatures.push({
            feature: feature,
            properties: feature.getProperties(),
            layer: layer,
          });
        });
        return findFeatures;
      },
      centerUpdate(e) {
        this.$emit('update:center', e);
      },
      // make sure render compelete
      // https://stackoverflow.com/questions/33061221/ensuring-all-tiles-are-loaded-in-open-layers-3-xyz-source
      postrender(event) {
        if (event.frameState == null) {
          return;
        }
        let wantedTilesCounter = 0;
        var wantedTiles = event.frameState.wantedTiles;
        for (var layer in wantedTiles) {
          if (wantedTiles[layer] == null) {
            continue;
          }
          wantedTilesCounter += Object.keys(wantedTiles[layer]).length;
        }
        this.tileWanted = wantedTilesCounter;
      },
      rendercomplete(event) {
        if (this.layersTile.length === 0) {
          // NO tile layers, REQUIRE extra check to make sure map loaded
          this.$emit('rendercomplete', event);
        } else if (this.tileWanted === 0 && this.tileToLoad > 0 && this.tileToLoad === this.tileLoaded) {
          // with tile layers, no extra check to make sure map loaded
          this.$emit('map-rendercomplete', event);
        }
      },
      async updateBounds() {
        if (this.olView == null) {
          await this.$refs.vlView.$mountPromise;
        }

        let glbox = this.olView.calculateExtent(this.olMap.getSize()); 
        let bottomLeft = toLonLat(getBottomLeft(glbox));
        let topRight = toLonLat(getTopRight(glbox));

        this.$emit('update:bounds', [
          bottomLeft[0],  // left
          bottomLeft[1],  // bottom
          topRight[0],    // right
          topRight[1],    // top
        ]);
      },
      async getOlMap() {
        if (this.$refs.vlMap.$olObject == null) {
          await this.$refs.vlMap.$mountPromise;
        }
        return this.$refs.vlMap.$olObject;
      },
      async getOlView() {
        if (this.$refs.vlView.$olObject == null) {
          await this.$refs.vlView.$mountPromise;
        }
        return this.$refs.vlView.$olObject;
      },
    },
    created() {
      let layers = this.layersTile.length +
        this.layersImage.length +
        this.layersVector.length +
        this.layersVectorTile.length;
      this.$emit('update:layersCount', layers);
    },
    async mounted() {
      this.updateBounds();
      this.olMap = await this.getOlMap();
      this.olView = await this.getOlView();
      this.$root.$ol = {
        map: this.olMap,
        view: this.olView,
        findFeatureOnCoordinate: (coordinate) => {
          return this.mapFindFeatureOnCoordinate(coordinate);
        },
      };
    },
    destroyed() {
      delete this.$root.$ol.map;
      delete this.$root.$ol.view;
      delete this.$root.$ol.findFeatureOnCoordinate;
      delete this.$root.$ol;
    },
    watch: {
      
    },
  };

</script>

<style lang="scss">
.open-layer-info {
  position: absolute;
  bottom: 0;
  left: 0;
}
</style>